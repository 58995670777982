import TemplateValidation from "../utils/TemplateValidation";

export default class BackofficeEligibilitiesIndex {

    constructor() {
        this.fttoDatatables = [];
        this.fonDataTable = null;
        this.otherDatatables = [];

        this.placesAddress = null;
        this.placesAddressA = null;
        this.placesAddressB = null;
        this.placesAddressWithoutApi = null;
        
        this.testValidation = null;
        this.fonExportValidation = null;
        this.fttoExportValidation = null;

        let urlParams = new URLSearchParams(window.location.search);
        this.fromWorkflow = urlParams.get('from_workflow');

        this.bindEvents();

        this.setDatatables();
        this.initAutocompletePlaces();
        this.initValidation();
        this.toggleSolution();
        //this.toggleTestModal();

        
    }

    bindEvents() {
        $("#service_id").on("change", this.onSolutionChange);
        $("#eligibility_request").on("submit", this.onSubmitTestEligibility);
        $(".js-export-fon").on("click", this.onExportFon);
        $(".js-export-ftto").on("click", this.onExportFtto);

        $("#entity-select").select2({});
    }

    bindDetailButtons() {  
      const self = this;
      
      $(".go-to-details-btn").on("click", function(){
          let uuid = $(this).data("uuid");
  
          $("#submit-entity-choice").data("uuid", uuid);
  
          // Trigger change event to load members for the first entity by default
          $("#entity-select").trigger("change");
      });
  
      $("#entity-select").on("change", function(){
          let url = $(this).data("get-members-select-url");
  
          $.ajax({
              url: url,
              data: {entity: $(this).val()},
              method: "GET",
              success: function(data){
                $("#members-select-wrapper").html(data);
                $("#entity-members-select").select2({});
              }
          });
      });
  
      $("#submit-entity-choice").on("click", function(){
          let uuid = $(this).data("uuid");
          let entity_name = $("#entity-select").val();
          let customer_id = $("#entity-members-select").val();

          var redirect_url = `/eligibilities/${uuid}?entity=${entity_name}&customer_id=${customer_id}`;

          if (self.fromWorkflow) {
            redirect_url += `&from_workflow=${self.fromWorkflow};`
          }
          
          window.location.href = redirect_url;
      });
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.fttoDatatables.forEach((table) => {
            table.destroy();
        });
        this.fttoDatatables = [];

        this.fonDataTable.destroy();
        this.fonDataTable = null;
        this.otherDatatables.forEach((table) => {
            table.destroy();
        });
        this.otherDatatables = [];

        $("#service_id").off("change", this.onSolutionChange)
        $("#onSubmitTestEligibility").off("submit", this.onSubmitTestEligibility)
        $(".js-export-fon").off("click", this.onExportFon)
        $(".js-export-ftto").off("click", this.onExportFtto)

        if(this.testValidation !== null){
            this.testValidation.onDestroy();
            this.testValidation = null;
        }

        if(this.fonExportValidation !== null){
            this.fonExportValidation.onDestroy();
            this.fonExportValidation = null;
        }

        if(this.fttoExportValidation !== null){
            this.fttoExportValidation.onDestroy();
            this.fttoExportValidation = null;
        }

        $("#entity-select").select2("destroy");
    }

    setDatatables() {
        this.initFttoDatatables();
        this.initFonDatatable();
        this.initOtherDatatables();
    }

    initFttoDatatables(){
        let self = this;

        let $els = $('.ftto_datatable');
        if($els.length <= 0){ return false; }

        $els.each(function() {
            let $el = $(this);
            let table = $el.DataTable({
                dom: 'rtp',
                responsive: false,
                serverSide: true,
                "pageLength": 20,
                order: [[0, 'desc']],
                language: require('../../backoffice/utils/plugins/datatables_translation/'+I18n.locale+'.json'),
                columnDefs: [
                    { orderable: false, searchable: false, targets: -1 },
                    { orderable: false, searchable: false, targets: 2 },
                ],
                "ajax": {
                    'url': $el.data("url"),
                    'data': function(data){
                        //data.search["value"] = $("#client_search").val();
                    }
                },
                "columns": [
                    { "data": "created_at" },
                    { "data": "address" },
                    { "data": "coordinates", "orderable": false },
                    { "data": "result" },
                    { "data": "last_checked_at", "orderable": false },
                    { "data": "actions", "orderable": false },
                ],
                initComplete: function() {
                    self.bindDetailButtons();
                }
            });

            self.fttoDatatables.push(table);

            let $search = $('#' + $el.data("service-name") + 'CustomSearch');

            if ($search.length !== 0) {
                $search.keyup(function() {
                    table.search($(this).val()).draw();
                });
            }
        });
    }

    initFonDatatable(){
        let self = this;

        let $el = $('#fon_datatable')
        if($el.length <= 0){ return false }

        this.fonDataTable = $el.DataTable({
            dom: 'rtp',
            serverSide: true,
            responsive: false,
            "pageLength": 20,
            order: [[0, 'desc']],
            language: require('../../backoffice/utils/plugins/datatables_translation/'+I18n.locale+'.json'),
            columnDefs: [
                { orderable: false, searchable: false, targets: -1 },
                { orderable: false, searchable: false, targets: 2 },
                { orderable: false, searchable: false, targets: 4 },
            ],
            "ajax": {
                'url': $el.data("url"),
                'data': function(data){
                    //data.search["value"] = $("#client_search").val();
                }
            },
            "columns": [
                { "data": "created_at" },
                { "data": "address" },
                { "data": "coordinates", "orderable": false },
                { "data": "secondary_address" },
                { "data": "secondary_coordinates", "orderable": false },
                { "data": "result" },
                { "data": "last_checked_at", "orderable": false },
                { "data": "actions", "orderable": false },
            ],
            initComplete: function() {
                self.bindDetailButtons();
            }
        })

        let $search = $('#customFonSearch')

        if($search.length !== 0){
            $search.keyup(function(){
                self.fonDataTable.search($(this).val()).draw();
            })
        }
    }

    initOtherDatatables() {
        let self = this;

        let $els = $('.other-datatable');
        if ($els.length <= 0) { return false; }

        $els.each(function() {
            let $el = $(this);
            let table = $el.DataTable({
                dom: 'rtp',
                responsive: false,
                serverSide: true,
                "pageLength": 20,
                order: [[0, 'desc']],
                language: require('../../backoffice/utils/plugins/datatables_translation/' + I18n.locale + '.json'),
                columnDefs: [
                    { orderable: false, searchable: false, targets: -1 },
                    { orderable: false, searchable: false, targets: 2 },
                ],
                "ajax": {
                    'url': $el.data("url"),
                    'data': function(data) {
                        //data.search["value"] = $("#client_search").val();
                    }
                },
                "columns": [
                    { "data": "created_at" },
                    { "data": "address" },
                    { "data": "coordinates", "orderable": false },
                    { "data": "last_checked_at", "orderable": false },
                    { "data": "actions", "orderable": false },
                ],
                initComplete: function() {
                    self.bindDetailButtons();
                }
            });

            self.otherDatatables.push(table);

            let $search = $('#' + $el.data("service-name") + 'CustomSearch');

            if ($search.length !== 0) {
                $search.keyup(function() {
                    table.search($(this).val()).draw();
                });
            }
        });
    }

    onSolutionChange(e) {
        let self = application.current_handler;
        self.toggleSolution();
    }

    toggleSolution(){
        let $el = $("#service_id option:selected");

        if($el.data("api") == "api_type_fon") {
            $("#ftto-container").hide();
            $("#no-service-container").hide();

            $("#fon-container").show();
            
            $("#no-service-address").val("");
            $("#address").val("");
        } else if($el.data("api") == "api_type_ftto_one" || $el.data("api") == "api_type_ftto_tree") {
            $("#fon-container").hide();
            $("#no-service-container").hide();

            $("#ftto-container").show();

            $("#no-service-address").val("");
            $("#address_a").val("");
            $("#address_b").val("");
        } else {
            $("#fon-container").hide();
            $("#ftto-container").hide();

            $("#no-service-container").show();  

            $("#address").val("");
            $("#address_a").val("");
            $("#address_b").val("");
        }
    }

    initAutocompletePlaces() {

        //autocomplete options
        const options = {
            componentRestrictions: { country: "fr" },
            types: ['address'],
        };
        //autocomplete offer 1
        this.placesAddress = new google.maps.places.Autocomplete(document.getElementById("address"), options);

        //autocomplete offer2
        this.placesAddressA = new google.maps.places.Autocomplete(document.getElementById("address_a"), options);
        this.placesAddressB = new google.maps.places.Autocomplete(document.getElementById("address_b"), options);

        //autocomplete no service
        this.placesAddressWithoutApi = new google.maps.places.Autocomplete(document.getElementById("no-service-address"), options);
    }

    onSubmitTestEligibility(e){
        e.preventDefault();
        let self = application.current_handler;
        let $el = $(e.currentTarget)

        if(!$el.valid()){ return false; }
        application.openLoader();
        let data = {
            address: $("#address").val() || $("#no-service-address").val(),
            address_a: $("#address_a").val(),
            address_b: $("#address_b").val(),
            solution: $("#nexloop_solution").val(),
            service_id: $("#service_id").val(),
            from_workflow: self.fromWorkflow
        };

        let ajax_data = {
            url: $el.data("url"),
            data: data,
            method: "POST",
            success: self.testEligibilitySuccess,
            error: self.testEligibilityError
        }

        $.ajax(ajax_data)
    }

    testEligibilitySuccess(data){
        application.closeLoader();
        Turbolinks.visit(data.redirect_url)
    }

    testEligibilityError(data){
        application.closeLoader();
        if(data.status === 404){
            $("#info_modal").modal("show")
            $("#info_modal #infoModalTitle").html(I18n.t("extranet.eligibility.index.test_eligibility_result.no_token_title"))
            $("#info_modal #infoModalContent").html(I18n.t("extranet.eligibility.index.test_eligibility_result.no_token_message"))
        }else{
            $("#info_modal").modal("show")
            $("#info_modal #infoModalTitle").html(I18n.t("extranet.eligibility.index.test_eligibility_result.request_error_title"))
            $("#info_modal #infoModalContent").html(data.responseJSON.error)
        }
    }

    initValidation() {
        this.testValidation = new TemplateValidation(this.validationUpdateData(), "#eligibility_request");
        this.fonExportValidation = new TemplateValidation(this.validationFonExportData(), "#export_fon_form");
        this.fttoExportValidation = new TemplateValidation(this.validationFttoExportData(), "#export_ftto_form");
    }

    validationUpdateData() {
        return {
            "address": {
                required: true
            },
            "address_a": {
                required: true
            },
            "address_b": {
                required: true
            },
            "nexloop_solution": {
                required: true
            }
        };
    }

    validationFonExportData() {
        return {
            "export_fon_start_date": {
                required: true
            },
            "export_fon_end_date": {
                required: true,
                greater_than: "#export_fon_start_date"
            }
        };
    }

    validationFttoExportData() {
        return {
            "export_ftto_start_date": {
                required: true
            },
            "export_ftto_end_date": {
                required: true,
                greater_than: "#export_ftto_start_date"
            }
        };
    }

    onExportFon(e){
        e.preventDefault();
        let $el = $(e.currentTarget)
        let self = application.current_handler;

        if(!$("#export_fon_form").valid()){ return false; }

        $("#export_fon_modal").modal("hide")

        self.exportRequests(
            $el.data("url"),
            $("#export_fon_start_date").val(),
            $("#export_fon_end_date").val()
        );
    }

    onExportFtto(e){
        e.preventDefault();
        let $el = $(e.currentTarget)
        let self = application.current_handler;

        if(!$("#export_ftto_form").valid()){ return false; }

        $("#export_ftto_modal").modal("hide")

        self.exportRequests(
            $el.data("url"),
            $("#export_ftto_start_date").val(),
            $("#export_ftto_end_date").val()
        );
    }

    exportRequests(url, start_date, end_date){
        application.openLoader();

        $.ajax({
            url: url,
            method: "POST",
            data: {
                start_date: start_date,
                end_date: end_date
            },
            success: function (data){
                application.closeLoader();
                window.open(data.url, '_blank')
            }
        })
    }

    // toggleTestModal(){
    //   if (this.getApplicationParam("modal_test") === "true") {
    //     console.log("toggleTestModal");
    //     $("#test_modal").modal("show");
    //   }
    // }

    getApplicationParam(param) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    }
}