export default class BackofficeServicesForm {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $('.select2').select2({
      placeholder: "Choisissez une ou plusieurs entreprises",
      allowClear: true,
      closeOnSelect: false
    }); 
  }

  onDataChanged(data) {

  }

  onDestroy() {

  }
}